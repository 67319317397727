import PublishErrorList from "./PublishErrorList";
import ValidationErrorList from "./ValidationErrorList";
import PublishConfirmation from "./PublishConfirmation";
import PublishSuccess from "./PublishSuccess";
import Spinner from "./Spinner";
import { useUserSettings } from "../../auth/useUserSettings";
import dayjs from "dayjs";
import fetchData from "./scripts/fetchDataNew.js";

export default function PublishSchedule({
  scheduleId,
  centre,
  getExportList,
  reloadPublishedSchedule,
  setShowNewModal,
  setNewModalContent,
  setClashClassList,
  showErrorModal,
}) {
  const [userSettings] = useUserSettings();
  const scheduleToPost = {
    schedule_id: scheduleId,
    centre_id: centre.value,
    createby: userSettings.admin_id,
    mode: 0,
    class_list: [],
  };
  const isVirtual = centre?.is_virtual;

  function createErrorList(list) {
    let newList = list.map((item) => {
      return { dateStr: null, id: item.id, type: null };
    });
    setClashClassList(newList);
    return newList;
  }

  const confirmPublishClasses = () => {
    if (scheduleToPost.mode === 1) {
      const classCount = scheduleToPost.class_list.length;
      setNewModalContent(
        <PublishConfirmation
          classCount={classCount}
          publishFunction={postSchedule}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    } else {
      postSchedule();
    }
  };

  async function sendMessage(scheduleName, classCount, response, duration) {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/feedback`;
    let messageBody = {
      subject: "t2 publish notification: " + userSettings.username,
      text:
        scheduleName + ": " + classCount + ": " + response + ": " + duration,
    };
    await fetchData(url, "POST", messageBody);
  }

  const postSchedule = async () => {
    let start_time = dayjs(new Date()).valueOf();
    let centreId = centre.centre_id;
    if (scheduleToPost.mode === 0) {
      console.log(scheduleToPost);
      setNewModalContent(
        <Spinner
          message="Checking for errors or clashes, please wait..."
          timer={true}
        />
      );
      setShowNewModal(true);
    }
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/publish-schedule/${centreId}`;
    let response = await fetchData(url, "POST", scheduleToPost);
    console.log(response);
    if (response.result === true) {
      response = response.data;
      if (response?.result === true) {
        let message = "";
        if (scheduleToPost.mode === 1) {
          setNewModalContent(
            <PublishSuccess setShowNewModal={setShowNewModal} />
          );
          reloadPublishedSchedule(scheduleToPost.schedule_id);
          let duration =
            (dayjs(new Date()).valueOf() - start_time) / 1000 + "s";
          sendMessage(
            scheduleId,
            scheduleToPost.class_list.length,
            response?.result,
            duration
          );
        } else {
          message = "No errors or clashes found.";
          setNewModalContent(message);
        }
      } else if (response?.result === false) {
        createErrorList(response.message.class_list);
        setNewModalContent(
          <PublishErrorList
            errorList={response.message}
            setShowNewModal={setShowNewModal}
          />
        );
      }
    } else {
      sendMessage(scheduleId + ":" + response.message);
      showErrorModal(response.message);
    }
    setShowNewModal(true);
  };

  function validatePublishSchedule(publishClassList) {
    let incompleteClassCount = 0;
    let pastClassCount = 0;
    let publishedClassCount = 0;
    const validationList = [
      "centre",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "classroom",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    const virtualValidationList = [
      "centres",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    for (const classInstance of publishClassList) {
      classInstance["class_id"] && publishedClassCount++;
    }
    for (const classInstance of publishClassList) {
      let emptyCellCount = 0;
      for (const item of isVirtual ? virtualValidationList : validationList) {
        classInstance[item] === "" && emptyCellCount++;
      }
      emptyCellCount > 0 && incompleteClassCount++;
    }
    for (const classInstance of publishClassList) {
      classInstance["dateStr"] < dayjs(new Date()).format() && pastClassCount++;
    }
    return {
      a: incompleteClassCount,
      b: pastClassCount,
      c: publishedClassCount,
    };
  }

  function getPublishSchedule(mode) {
    let publishClassList = getExportList();
    let errors = validatePublishSchedule(publishClassList);
    if (errors.a === 0 && errors.b === 0 && errors.c === 0) {
      let classArray = [];
      if (publishClassList.length > 0) {
        for (const item of publishClassList) {
          let newItem = {};
          newItem["id"] = item.temp_id;
          newItem[item.lesson.type] = item.lesson?.value;
          newItem["lesson_time"] = dayjs
            .utc(item.dateStr)
            .format("YYYY-MM-DD HH:mm:ss");
          newItem["teacher"] = Number(item.teacher?.value);
          newItem["classroom"] = item.classroom?.value;
          newItem["duration"] = Number(item.duration?.value);
          newItem["capacity"] = Number(item.capacity?.value);
          newItem["delivery"] = isVirtual ? 2 : 1;
          newItem["credit"] = Number(item.credit?.value);
          newItem["levels"] =
            item.levels.length > 0 &&
            item.levels.map((level) => {
              return Number(level.value);
            });
          newItem["products"] =
            item.products.length > 0 &&
            item.products.map((product) => {
              return product.value;
            });
          if (isVirtual) {
            newItem["centres"] =
              item.virtualCentres.length > 0 &&
              item.virtualCentres.map((virtualCentre) => {
                return virtualCentre.value;
              });
          }
          newItem["closed"] = Number(item.isClosed?.value);
          if (item.module) {
            newItem["module_code"] =
              item.module?.value.split(":")[0] +
              ":" +
              item.module?.value.split(":")[1] +
              ":" +
              item.module?.value.split(":")[2] +
              ":" +
              1;
          }
          if (item.lesson.session_name) {
            newItem["session_name"] = item.lesson.session_name;
          }
          if (item.lesson.session_description) {
            newItem["session_description"] = item.lesson.session_description;
          }
          classArray.push(newItem);
        }
        scheduleToPost["class_list"] = classArray;
        scheduleToPost["mode"] = mode;
        confirmPublishClasses();
      }
    } else {
      setNewModalContent(
        <ValidationErrorList
          errors={errors}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    }
  }

  return (
    <>
      <button
        className="schedule-form-button"
        onClick={() => getPublishSchedule(0)}
      >
        Check
      </button>
      <button
        className="schedule-form-button"
        onClick={() => getPublishSchedule(1)}
      >
        Publish
      </button>
    </>
  );
}
