import { useState } from "react";
import Spinner from "./Spinner";

export default function PublishConfirmation({
  classCount,
  publishFunction,
  setShowNewModal,
}) {
  const [isPublishing, setIsPublishing] = useState(false);

  function publish() {
    publishFunction();
    setIsPublishing(true);
  }
  return (
    <div>
      {!isPublishing ? (
        <>
          <div className="delete-message-div">
            You are publishing {classCount} class{classCount > 1 && "es"}. Are
            you sure?
          </div>
          <div className="delete-button-div">
            <button
              className="schedule-form-button publish-button"
              onClick={publish}
            >
              Sure
            </button>
            <button
              className="schedule-form-button"
              onClick={() => setShowNewModal(false)}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <Spinner
          message="Publishing classes, please wait a moment ..."
          timer={true}
        />
      )}
    </div>
  );
}
